<template>
  <div>
    <div
      class="cb-message parcel-selected"
      :class="{'is-bot' : data.isBot}">
      <span
        ref="icon"
        class="cb-message-icon">
        <avatar-bot />
      </span>
      <div
        ref="text"
        class="cb-message-text">
        <span class="time-hour">{{ timeHour }}</span>
        <v-card class="elevation-0">
          <v-toolbar
            dark
            color="channelSms"
            height="60"
            flat>
            <v-avatar
              color="grey lighten-4"
              size="80">
              <img
                src="@/assets/images/fake/senac.jpg"
                :alt="`Logo da empresa ${company.fantasyName}`">
            </v-avatar>
          </v-toolbar>

          <v-toolbar-title class="darkGray--text text-center mx-0 mb-3">
            Parcelamento
          </v-toolbar-title>
          <v-layout>
            <v-flex
              xs12
              md6
              class="">
              <b>Parcela</b>
            </v-flex>
            <v-flex
              xs12
              md6
              class="">
              <b>Data de vencimento</b>
            </v-flex>
          </v-layout>
          <v-divider class="my-1" />
          <v-layout
            v-for="(parcel, index) in allParcels"
            :key="index">
            <v-flex
              xs12
              md6
              class="">
              <span>{{ index + 1 }}ª de {{ $n(parcelSelected.parcelValue, 'currency') }}</span>
            </v-flex>
            <v-flex
              xs12
              md6>
              {{ date }}/{{ index + 1 }}/2019
            </v-flex>
          </v-layout>
          <v-divider class="my-1" />
          <v-layout>
            <v-flex
              xs12
              md6
              class="">
              <b>Total</b>
            </v-flex>
            <v-flex
              xs12
              md6>
              <b>{{ $n(parcelSelected.currentValue, 'currency') }}</b>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
  // import Message from './Message'
  import chatUiMixin from '@/mixins/chat-ui'
  import AvatarBot from '../AvatarBot'

  export default {
    components: { AvatarBot },
    mixins: [ chatUiMixin ],
    computed: {
      parcelSelected () {
        let index = this.data.options.parcel - 1
        return this.data.options.proposal.installments[index]
      },
      company () {
        return this.data.options.proposal.company
      },
      allParcels () {
        return this.data.options.parcel
      },
      date () {
        return this.data.options.date
      }
    },
    mounted () {
      this.iconAnimation()
      this.messageAnimation()
      console.log('parcelDetails', this.data.options)
    },
    methods: {
      createData () {

      }
    }
  }
</script>

<style lang="sass">
  .parcel-selected
    display: flex
    align-items: flex-start

    .cb-message-text
      margin-top: 30px
      font-size: 14px
      width: 350px

    .v-toolbar__title
      padding-top: 50px

    .v-card
      color: $textColor

      .v-avatar
        position: absolute
        bottom: -35px
        left: 50%
        transform: translateX(-50%)
        box-shadow: 0px 0px 0px 4px $bgColor, 0px 0px 0px 8px $light

      .layout
        margin: 3px

      .details:last-child
        border-bottom: 1px solid $gray

  @media screen and (max-width: $max-mobile)
    .parcel-selected
      .cb-message-text
        max-width: 90%
        width: 90%
</style>
